import PropTypes from "prop-types";
import React, { createContext } from "react";
import useDarkMode from "../hooks/useDarkMode";

export const ThemeContext = createContext("light");

interface Props {
  children: React.ReactNode;
}

const ThemeProvider = (props: Props): JSX.Element => {
  const [theme] = useDarkMode();

  return <ThemeContext.Provider value={theme}>{props.children}</ThemeContext.Provider>;
};
ThemeProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};

export default ThemeProvider;
