exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-gaze-tsx": () => import("./../../../src/pages/gaze.tsx" /* webpackChunkName: "component---src-pages-gaze-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learnbrite-tsx": () => import("./../../../src/pages/learnbrite.tsx" /* webpackChunkName: "component---src-pages-learnbrite-tsx" */),
  "component---src-pages-monty-tsx": () => import("./../../../src/pages/monty.tsx" /* webpackChunkName: "component---src-pages-monty-tsx" */)
}

